<template>
  <v-container id="view-users" tag="section">
    <base-material-card
      color="default"
      icon="mdi-account-outline"
      inline
      class="px-5 py-3"
      :elevation="8"
    >
      <v-container>
        <v-data-table
          :headers="headers"
          :items="users.records"
          item-key="id"
          :loading="isLoading"
          :search="search"
          :sort-by.sync="sortBy"
        >
          <template v-slot:top>
            <v-carousel
              :dark="$vuetify.theme.dark"
              :light="!$vuetify.theme.dark"
              hide-delimiter-background
              height="150"
            >
              <v-carousel-item>
                <v-row justify="center">
                  <v-col md="3">
                    <v-text-field v-model="search" label="Search" outlined />
                  </v-col>
                </v-row>
              </v-carousel-item>
              <v-carousel-item>
                <v-row class="my-2">
                  <v-col cols="12" sm="3" md="3" lg="3" />
                  <v-col cols="12" sm="6" md="6" lg="3">
                    <download-excel
                      class="text-right"
                      :data="users.records"
                      :fields="fields"
                      worksheet="My Worksheet"
                      name="FairwageListofUsers.xls"
                    >
                      <v-btn class="text-right" color="#0a2752" large dark>
                        <h1>Download</h1>
                      </v-btn>
                    </download-excel>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6">
                    <v-dialog v-model="dialog" max-width="900px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mx-2"
                          color="primary"
                          large
                          v-bind="attrs"
                          v-on="on"
                        >
                          <h1>Bulk User Upload</h1>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Bulk User Upload</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <base-user-bulk-create />
                          </v-container>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-carousel-item>
            </v-carousel>
          </template>
          <template v-slot:item.firstName="{ item }">
            <h5 color="primary">
              {{ item.firstName + " " }}{{ item.lastName }}
            </h5>
          </template>

          <template v-slot:item.createdAt="{ item }">
            <h5 color="primary">
              {{ item.createdAt | getFormattedDate }}
            </h5>
          </template>
          <template v-slot:item.updatedAt="{ item }">
            <h5 color="primary">
              {{ item.updatedAt | getFormattedDate }}
            </h5>
          </template>
          <!-- <template v-slot:item.role="{ item }">
		  <v-chip label>
		    {{ item.role ? $t(item.role) : "None" }}
		  </v-chip>
		</template> -->

          <template v-slot:item.management="{ item }" class="text-center">
            <v-btn> Manage </v-btn>
          </template>
        </v-data-table>
      </v-container>
    </base-material-card>
  </v-container>
</template>

<script>
import { gql } from "apollo-boost";
// import defaultProfile from "@/assets/avatar.png";
export default {
  name: "ViewSupport",
  data() {
    return {
      statusQuery: "",
      fields: {
        "Full Name": "firstName" + "lastName",
        "Email Address": "email",

        "Created At": "createdAt",
      },
      headers: [
        // {
        //   text: 'ID',
        //   value: 'id',
        // },

        {
          text: "Full Name",
          value: "firstName",
          align: "left",
        },

        {
          text: "Email",
          value: "email",
          align: "left",
        },
        {
          text: "Created",
          value: "createdAt",
          filterable: false,
        },

        {
          sortable: false,
          text: "Manage",
          value: "management",
          align: "center",
          filterable: false,
        },
      ],
      dialog: false,
      search: "",
      sortBy: "firstName",
      users: [],
      totalRecords: 0,
      isLoading: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
          email
          firstName
          lastName
          role
        }
      }
    `,
    users: {
      query: gql`
        query Users($limit: Int!, $page: Int!, $query: JSON!) {
          users(limit: $limit, page: $page, query: $query) {
            records {
              id
              firstName
              lastName
              consultant
              idNumber
              email
              role
              createdAt
              updatedAt
            }
            # count
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        return {
          limit: 9999,
          page: 1,
          query: { consultant: this.me.id },
        };
      },
      watchLoading(isLoading, countModifier) {
        console.log("isLoading", isLoading);
        this.isLoading = isLoading;
      },
      result(data, key) {
        console.log(data, key);
      },
    },
  },
  computed: {},
  watch: {},
  methods: {
    async created() {
      this.$eventHub.$on("user-updated", this.$apollo.queries.users.refetch());
      // this.$eventHub.$on('user-updated', this.refreshData)
    },
    beforeDestroy() {
      this.$eventHub.$off("user-updated");
    },
    refreshData() {
      console.log("Refreshing data for users");
      this.$apollo.queries.users.refetch();
    },
    customFilter(value, search, item) {
      console.log(value, search, item);
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
    defaultProfileImage() {
      return defaultProfile;
    },
  },
};
</script>
<style lang="sass"></style>
